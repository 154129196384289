import { standardFormFieldNames, standardFormSettingNames } from '@helpers/form';

export const formIdSetting = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element.',
    },
];

export const siteWideDemoFormSettings = [
    {
        name: 'marketing-cloud-id',
        description: 'The marketing cloud API ID provided by a MC admin, default: demo-request-multi-step',
    },
    {
        name: 'return-url',
        description: 'The return url after the form has been submitted',
    },
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "EVLeadForm")',
    },
    {
        name: 'form-title',
        description: 'The title of the PPC form',
    },
    {
        name: 'form-subtitle',
        description: 'The subtitle text of the PPC form',
    },
];

export const OEMFormSettings = [
    {
        name: 'company-label',
        description: 'The label below the "Company" input field. (default: "Company")',
    },
    {
        name: 'company-placeholder',
        description: 'The placeholder for the "Company" input field. (default: "Enter a company")',
    },
    {
        name: 'return-url',
        description: 'The return url after the form has been submitted',
    },
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "OEMForm")',
    },
];

export const OEMFormEUSettings = [
    {
        name: 'company-label',
        description: 'The label below the "Company" input field. (default: "Company")',
    },
    {
        name: 'company-placeholder',
        description: 'The placeholder for the "Company" input field. (default: "Enter a company")',
    },
    {
        name: 'return-url',
        description: 'The return url after the form has been submitted',
    },
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "OEMFormEU")',
    },
];

export const EVLeadFormSettings = [
    {
        name: 'company-label',
        description: 'The label below the "Company" input field. (default: "Company")',
    },
    {
        name: 'company-placeholder',
        description: 'The placeholder for the "Company" input field. (default: "Enter a company")',
    },
    {
        name: 'return-url',
        description: 'The return url after the form has been submitted',
    },
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "EVLeadForm")',
    },
    {
        name: 'marketing-cloud-id',
        description: 'The marketing cloud form ID. (default: "ev-global-form")',
    },
    {
        name: 'form-title',
        description: 'The title of the PPC form',
    },
    {
        name: 'form-subtitle',
        description: 'The subtitle text of the PPC form',
    },
];

export const gatedWhitePaperFormMCSettings = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "gatedWhitePaperForm")',
    },
    {
        name: 'marketing-cloud-form-id',
        description: 'The marketing cloud form ID. (default: "gated-content")',
    },
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
    {
        name: 'hidden-var',
        description:
            'An optional value that is passed along with the form submission using the form field "hidden_var"',
    },
    {
        name: standardFormSettingNames.leadSource,
        description: `An optional value that is passed along with the form submission using the form field "${standardFormFieldNames.leadSource}"`,
    },
    {
        name: standardFormSettingNames.leadSourceAdditionalDetails,
        description: `An optional value that is passed along with the form submission using the form field "${standardFormFieldNames.leadSourceAdditionalDetails}"`,
    },
];

export const PPCLandingFormSettings = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "ppc-landing-form")',
    },
    {
        name: 'form-title',
        description: 'The main title of the form.',
    },
    {
        name: 'marketing-cloud-form-id',
        description: 'The marketing cloud form ID. (default: "ppc-demo-request-global")',
    },
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
    {
        name: 'language-code',
        description: 'The list of accepted language code from Salesforce, (default: English Global)',
    },
    {
        name: 'submit-button-text',
        description: 'The text of the submit button',
    },
];

export const latamGatedWhitePaperMCSettings = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "gatedWhitePaperForm")',
    },
    {
        name: 'marketing-cloud-form-id',
        description: 'The marketing cloud form ID. (default: "latam-whitepaper")',
    },
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
    {
        name: standardFormSettingNames.leadSource,
        description: `An optional value that is passed along with the form submission using the form field "${standardFormFieldNames.leadSource}"`,
    },
    {
        name: standardFormSettingNames.leadSourceAdditionalDetails,
        description: `An optional value that is passed along with the form submission using the form field "${standardFormFieldNames.leadSourceAdditionalDetails}"`,
    },
];

export const becomeAResellerSetting = [
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
    ...formIdSetting,
];

export const salesInquiryFormMCSetting = [
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
];

export const MCMicroSiteFormMCSettings = [
    {
        name: 'company-label',
        description: 'The label below the "Company" input field. (default: "Company")',
    },
    {
        name: 'company-placeholder',
        description: 'The placeholder for the "Company" input field. (default: "Enter a company")',
    },
    {
        name: 'return-url',
        description: 'The return url after the form has been submitted',
    },
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "EVLeadForm")',
    },
];

export const transportationPageContactFormSettings = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element.',
    },
    {
        name: 'form-title',
        description: 'The main title of the form.',
    },
    {
        name: 'form-subtitle',
        description: 'The subtitle of the form.',
    },
    {
        name: 'marketing-cloud-form-id',
        description: 'The marketing cloud form ID. (default: "transportation-global-form")',
    },
    {
        name: 'return-url',
        description:
            'Url to forward the user to after submission of the form. (default: Thank you page of language site)',
    },
];

export const EVSADemoFormSettings = [
    {
        name: 'html-form-id',
        description: 'The ID attribute of the HTML <Form> element. (default: "ppc-landing-form")',
    },
    {
        name: 'form-title',
        description: 'The main title of the form.',
    },
    {
        name: 'marketing-cloud-form-id',
        description: 'The marketing cloud form ID. (default: "evsa-demo-request")',
    },
    {
        name: 'return-url',
        description: 'Url to forward the user to after submission of the form.',
    },
    {
        name: 'language-code',
        description: 'The list of accepted language code from Salesforce, (default: English Global)',
    },
    {
        name: 'submit-button-text',
        description: 'The text of the submit button',
    },
];

export const connect2024PageSettings = [
    {
        name: 'header-video',
        description: 'The header background video displayed on desktop screens',
    },
    {
        name: 'mobile-header-image',
        description: 'The header background image displayed on mobile screens',
    },
    {
        name: 'countdown-date-time',
        description: 'The date and time of Connect 2024 in UTC format',
    },
    {
        name: 'keynote-speakers',
        description:
            'A JSON object representing an array of keynote speaker objects used for the keynote speakers section',
    },
    {
        name: 'embedded-video-url',
        description: 'A vidyard url for the video section',
    },
    {
        name: 'sponsors',
        description: 'A JSON object representing an array of sponsor objects used for the sponsors section',
    },
];

export const postConnect2024PageSettings = [
    {
        name: 'header-video',
        description: 'The header background video displayed on desktop screens',
    },
    {
        name: 'mobile-header-image',
        description: 'The header background image displayed on mobile screens',
    },
    {
        name: 'embedded-video-url',
        description: 'A vidyard url for the video section',
    },
    {
        name: 'sponsors',
        description: 'A JSON object representing an array of sponsor objects used for the sponsors section',
    },
    {
        name: 'video-tags',
        description:
            'A JSON object representing an array of tag objects, used to determine which videos are displayed in the video hub section',
    },
];

export const quotingToolFormSettings = [
    {
        name: 'core-csv-file-url',
        description: 'The Google spreadsheet URL for the core products in .csv file format.',
    },
    {
        name: 'non-core-csv-file-url',
        description: 'The Google spreadsheet URL for the non-core products in .csv file format.',
    },
    {
        name: 'google-form-link',
        description: 'The google form link for the form submission.',
    },
    {
        name: 'core-label',
        description: 'The text string for the core product filter button.',
    },
    {
        name: 'non-core-label',
        description: 'The text string for the non-core product filter button.',
    },
    {
        name: 'hidden-quote',
        description: 'The entry ID for the hidden quote field.',
    },
    {
        name: 'security-field',
        description: 'The entry ID for the security field.',
    },
    {
        name: 'security-key',
        description: 'The secret password the google form accepts.',
    },
];
